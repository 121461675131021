import * as Amplitude from '@amplitude/analytics-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import Data from './components/data';
import Footer from './components/footer';
import Header from './components/header';
import Partner from './components/partner';
import {GlobalConfiguration} from './configurations';

import './index.css';

Amplitude.init(GlobalConfiguration.REACT_APP_AMPLITUDE);

ReactGA.initialize(GlobalConfiguration.REACT_APP_GOOGLE_ANALYTICS);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Header />
    <Partner />
    <Data />
    <Footer />
  </React.StrictMode>
);
