import IconChartBar from "../../shareds/icons/IconChartBar";
import IconCommandLine from "../../shareds/icons/IconCommandLine";
import IconIdentification from "../../shareds/icons/IconIdentification";
import IconPresentationChartLine from "../../shareds/icons/IconPresentationChartLine";

import "./styles.css";

export default function Data() {

  const features = [
    {
      description: 'Aprofunde-se no mercado, otimize suas estratégias de prospecção, avaliando segmentos de atuação com a base de dados B2B mais abrangente. Fortaleça seu funil de vendas com leads qualificados.',
      icon: IconChartBar,
      name: 'Identificação de mercado'
    },
    {
      description: 'Descubra os traços dos clientes mapeando suas características principais e integre insights da Decke com sua base de clientes, usando inteligência artificial para análise comparativa.',
      icon: IconIdentification,
      name: 'Cliente ideal'
    },
    {
      description: 'Aproveite a Inteligência Artificial na prospecção para sugestões de clientes, priorização e previsão de receita.',
      icon: IconPresentationChartLine,
      name: 'Propensão de negócios'
    },
    {
      description: 'Consulte dados fiéis das empresas que se relacionam com você e mantenha seus dados sempre atualizado.',
      icon: IconCommandLine,
      name: 'Enriquecimento de dados'
    }
  ];

  return (
    <div
      className='bg-white py-24 sm:py-32'
    >
      <div
        className='mx-auto max-w-7xl px-6 lg:px-8'
      >
        <div
          className='mx-auto max-w-2xl lg:text-center'
        >
          <p
            className='mt-2 text-3xl font-bold tracking-tight text-[#121212] sm:text-4xl'
          >
            Descubra oportunidades e impulsione seu crescimento de forma eficaz e direcionada
          </p>
        </div>
        <div
          className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl'
        >
          <dl
            className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-8 lg:max-w-none lg:grid-cols-2 lg:8'
          >
            {features.map((feature) => (
              <div
                className='container'
                key={feature.name}
              >
                <dt
                  className='text-base font-semibold leading-7 text-[#121212]'
                >
                  <div
                    className='pb-3 text-gray-600'
                  >
                    <feature.icon
                      aria-hidden='true'
                      className='pb-2 h-6 w-6 text-[#121212]'
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd
                  className='mt-2 text-sm leading-7 text-gray-600'
                >
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );

}
