import {Dialog} from '@headlessui/react';
import {ArrowRightIcon, Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {useState} from 'react';

import DeckeIcon from '../../assets/images/icon/decke.svg';
import HeaderImage from '../../assets/images/photo/header.svg';

export default function Header() {

  const navigation = [
    { name: 'Documentação', href: 'https://app.swaggerhub.com/apis-docs/decke-data/Decke/1.0.0' },
    { name: 'Status', href: 'https://status.decke.com.br' }
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className='bg-white'>
      <header className='bg-white border-b inset-x-0 ml-6 mr-6 top-0 z-50'>
        <nav className='bg-white flex items-center justify-between pb-3 pt-3' aria-label='Global'>
          <div className='flex lg:flex-1'>
            <a href='https://www.decke.com.br' className='-m-1.5 p-1.5'>
              <span className='sr-only'>Decke</span>
              <img
                alt='Decke'
                className='h-8 w-auto'
                src={DeckeIcon}
              />
            </a>
          </div>
          <div className='flex lg:hidden'>
            <button
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white'
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className='sr-only'>Abrir</span>
              <Bars3Icon className='h-6 w-6' aria-hidden='true'/>
            </button>
          </div>
          <div className='hidden lg:flex lg:gap-x-12'>
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className='text-sm font-semibold leading-6 text-[#121212] hover:text-[#333]'>
                {item.name}
              </a>
            ))}
          </div>
          <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
            <a href='https://app.decke.com.br' className='text-sm font-semibold leading-6 text-[#121212] hover:text-[#333]'>
              Acessar <span aria-hidden='true'>&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog className='lg:hidden' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className='fixed inset-0 z-50'/>
          <Dialog.Panel
            className='fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
            <div className='flex items-center justify-between'>
              <a href='https://www.decke.com.br' className='-m-1.5 p-1.5'>
                <span className='sr-only'>Decke</span>
                <img
                  className='h-8 w-auto'
                  src={DeckeIcon}
                  alt='Decke'
                />
              </a>
              <button
                type='button'
                className='-m-2.5 rounded-md p-2.5 text-white'
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className='sr-only'>Fechar</span>
                <XMarkIcon className='h-6 w-6' aria-hidden='true'/>
              </button>
            </div>
            <div className='mt-6 flow-root'>
              <div className='-my-6 divide-y divide-gray-500/10'>
                <div className='space-y-2 py-6'>
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#121212] hover:text-[#333]'
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className='py-6'>
                  <a
                    href='https://app.decke.com.br'
                    className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-[#121212] hover:text-[#333]'
                  >
                    Acessar
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div className='relative px-6 pt-14 lg:px-8'>
        <div
          className='absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80'
          aria-hidden='true'
        >
          <div
            className='relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]'
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className='animate mx-auto max-w-4xl pb-16 pt-32 sm:pb-24 sm:pt-48 lm:pb-48 lg:pt-56'>
          <div className='text-center'>
            <h1 className='text-4xl font-bold tracking-tight text-[#121212] sm:text-6xl'>
              Dados, inteligência, crescimento.
            </h1>
            <p className='mt-6 text-lg leading-8 text-[#121212]'>
              Transforme dados e IA em estratégia e ações<br/>de crescimento para o seu negócio.
            </p>
            <div className='flex justify-center'>
              <div className='mt-10 flex items-center justify-center gap-x-6 pr-1'>
                <a
                  href='https://app.decke.com.br/authentication/sign-in'
                  className='rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600'
                >
                  Acessar
                </a>
              </div>
              <div className='mt-10 flex items-center justify-center gap-x-6 pl-1'>
                <a
                  href='https://app.decke.com.br/authentication/sign-up'
                  className='flex rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-blue-500 hover:bg-blue-100/50'
                >
                  Conhecer
                  <ArrowRightIcon className='h-6 pl-1 w-6' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='border flex justify-center ml-60 mr-60 rounded-2xl w-auto'>
        <img
          alt='Aplicação'
          className='rounded-2xl'
          src={HeaderImage}
        />
      </div>
    </div>
  );

}
