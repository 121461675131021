import {useState} from 'react';

import LinkedInIcon from '../../assets/images/icon/linkedin.svg';

export default function Footer() {

  const [disable, setDisable] = useState(true);
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');

  async function handleSubmit(event) {
    try {
      event.preventDefault();
      await fetch('https://api.decke.com.br/v1/inscricoes', {
        body: JSON.stringify({
          email: email
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })
        .then(response => {
          if (response.status === 204) {
            setFeedback('Seu e-mail foi inscrito!');
          }
          else if (response.status === 409) {
            setFeedback('Seu e-mail já está inscrito!');
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (error) {
      console.error(`Error: ${error}`);
    } finally {
      setEmail('');
      setDisable(true);
    }
  }

  return (
    <div
      className='border-t overflow-hidden relative bg-white ml-6 mr-6 py-16 sm:py-24 lg:py-32'
    >
      <div
        className='mx-auto max-w-7xl px-6 lg:px-8'
      >
        <div
          className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2'
        >
          <div
            className='max-w-xl lg:max-w-lg'
          >
            <h4
              className='text-3xl font-bold tracking-tight text-[#121212] sm:text-4xl'
            >
              Deseja receber conteúdos exclusivos?
            </h4>
            <p
              className='mt-4 text-md leading-8 text-[#121212]'
            >
              Ao clicar no botão ao lado, você concorda que a Decke armazene e processe as informações pessoais enviadas
              acima para fornecer o conteúdo solicitado.
            </p>
            <form
              className='space-y-6'
              onSubmit={handleSubmit}
            >
              <div
                className='mt-6 flex max-w-md gap-x-4'
              >
                <label
                  className='sr-only'
                  hidden={feedback !== ''}
                  htmlFor='email-address'
                >
                  Seu e-mail
                </label>
                <input
                  autoComplete='email'
                  className='block flex-1 border-1 border-gray-400 rounded-md bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                  hidden={feedback !== ''}
                  id='email-address'
                  name='email'
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setDisable(false);
                  }}
                  placeholder='Seu e-mail'
                  required
                  type='email'
                  value={email}
                />
                <button
                  className='disabled:ring-0 rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600'
                  disabled={disable}
                  hidden={feedback !== ''}
                  type='submit'
                >
                  Inscrever
                </button>
              </div>
              <div>
                <p
                  className='text-lg'
                  hidden={feedback === ''}
                >
                  {feedback}
                </p>
              </div>
            </form>
          </div>
          <dl
            className='grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-1 lg:pt-2'
          >
            <div
              className='flex flex-row items-end justify-end'
            >
              <a
                href='https://www.linkedin.com/company/decke-data'
                rel='noreferrer'
                target='_blank'
              >
                <div
                  className='hover:ring-1 hover:ring-white rounded-md bg-white/5 p-2 ring-1 ring-white/10'
                >
                  <span className='sr-only'>LinkedIn</span>
                  <img
                    alt='Decke'
                    className='h-8 w-auto'
                    src={LinkedInIcon}
                  />
                </div>
              </a>
            </div>
          </dl>
        </div>
      </div>
      <div
        aria-hidden='true'
        className='absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6'
      >
        <div
          className='aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30'
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  );

}
