import AwsActivateLogo from '../../assets/images/logo/aws-activate.svg';
import G4EducacaoLogo from '../../assets/images/logo/g4-educacao.svg';
import ITSHealthLogo from '../../assets/images/logo/its-health.svg';
import MongoDBStartupsLogo from '../../assets/images/logo/mongodb-startups.svg';
import RajaVenturesLogo from '../../assets/images/logo/raja-ventures.svg';

export default function Partner() {

  return (
    <div
      className='bg-white py-24 sm:py-32'
    >
      <div
        className='max-w-5xl md:max-w-7xl mx-auto pt-20 px-6 py-12 relative sm:py-24'
      >
        <h1 className='font-bold text-center text-4xl'>
          Muitos usam a Decke todos os dias
        </h1>
        <h2
          className='font-normal text-center text-lg leading-8 mt-6 pb-4 text-[#121212]'
        >
          Capacitando a próxima geração de<br />empresas de alto impacto
        </h2>
        <div
          className='mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5'
        >
          <img
            alt='AWS Activate'
            className='col-span-2 max-h-12 object-contain lg:col-span-1'
            height={48}
            src={AwsActivateLogo}
            width={158}
          />
          <img
            alt='G4 Educação'
            className='col-span-2 max-h-12 object-contain lg:col-span-1'
            height={48}
            src={G4EducacaoLogo}
            width={158}
          />
          <img
            alt='ITS Health'
            className='col-span-2 max-h-12 object-contain lg:col-span-1'
            height={48}
            src={ITSHealthLogo}
            width={158}
          />
          <img
            alt='MongoDB Startups'
            className='col-span-2 max-h-12 object-contain lg:col-span-1'
            height={48}
            src={MongoDBStartupsLogo}
            width={158}
          />
          <img
            alt='Raja Ventures'
            className='col-span-2 max-h-12 object-contain sm:col-start-2 lg:col-span-1'
            height={48}
            src={RajaVenturesLogo}
            width={158}
          />
        </div>
      </div>
    </div>
  );

}
